import React, { Component } from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import './Category.css';
import 'moment/locale/is';
import { Helmet } from 'react-helmet';
moment.locale('is');


class BookingConfirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            car: '',
            location: '',
            price: '',
            time: '',
            extras: '',
            daxRef: '',
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.location.state) {
            this.props.history.push('/til-leigu-sendibilar');
        } else {
            const { car, location, price, time, extras, daxRef } = this.props.location.state;
            this.setState({ car, location, price, time, extras, daxRef }, () => {
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: null
                });
            });

        }
    }
    render() {
        const { car, location, price, time, extras, daxRef } = this.state;
        return (
            <Container style={{ padding: '20px' }}>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                {car && location && price && time && daxRef ? (
                    <Col>
                        <Row className="justify-content-center"><Col md="auto"><h2>Bókun staðfest, bókunarnúmer: {daxRef}</h2></Col></Row>
                        <Row>
                            <Col className="PlaceTimeInfo">
                                <h4>Dags og staðsetningar</h4>
                                <h5>Afhendingar- og skilastaður</h5>
                                <p>{location.address} {location.postCode} {location.name}</p>
                                <p>{location.description}</p>
                                <p>Sendibílum skal skilað á sama stað og þeir eru leigðir.</p>
                                <h5>Sækja bíl</h5>
                                <p>{moment(time.pickup).format('dddd DD.MM.YYYY [Klukkan] HH:mm')}</p>
                                <h5>Skila bíl</h5>
                                <p>{moment(time.return).format('dddd DD.MM.YYYY [Klukkan] HH:mm')}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="CarInfo">
                                <h4>Tryggingar, Aukahlutir og ökutæki</h4>
                                <table style={{ "width": "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td><strong>Tryggingar</strong></td>
                                            <td>
                                                <p>{extras.insuranceSelected.length > 0 ? (extras.insuranceSelected.map(item => (item.name + ', '))) : ('Enginn')}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign="top"><strong>Aukahlutir</strong></td>
                                            <td>
                                                <p>{extras.extrasSelected.length > 0 ? (extras.extrasSelected.map(item => item.name + ', ')) : ('Enginn')}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%"><strong>Ökutæki</strong></td>
                                            <td>{car.name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <img src={car.image} alt={car.name} />
                                <div dangerouslySetInnerHTML={{ __html: car.description }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="priceInfo">
                                <h4>Verð (Verð innifelur virðisaukaskatt)</h4>
                                <table style={{ "width": "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td >Leiguverð</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.carPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td >ISK</td>
                                        </tr>
                                        <tr>
                                            <td >Kílómetragjald innifalið (skattur án vsk)</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.totalKmPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td >ISK</td>
                                        </tr>
                                        <tr>
                                            <td>Tryggingar</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.insurancePrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td >ISK</td>
                                        </tr>
                                        <tr>
                                            <td>Aukahlutir</td>
                                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                                            <td>ISK</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>
                                                <strong>Samtals</strong>
                                            </td>
                                            <th style={{ "textAlign": "right" }}><strong><CurrencyFormat value={price.carPrice + price.insurancePrice + price.extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></strong></th>
                                            <th>ISK</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                        <Row style={{ borderTop: '1px solid black', paddingTop: '10px' }}>
                            <Col>
                                <h5>Ekki er tekið við fyrirframgreiddum kreditkortum. Athugið að kreditkort verður að vera á nafni leigutaka en leigutaka er heimilt að tilnefna ökumann í sinn stað þegar hann sækir bílinn. Ökumaður þarf að framvísa gildu ökuskírteini við undirritun leigusamnings.</h5>
                            </Col>
                        </Row>
                    </Col>
                ) : (null)}
            </Container>
        );
    }
};



export default BookingConfirm;