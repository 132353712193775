import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt, faBars, faPhone, faClock, faQuestionCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import Header from './components/Header/Header';
import NavbarHead from './components/Header/NavbarHead';
import Main from './components/Main/Main';
import NavbarFoot from './components/Footer/NavbarFoot';
import Footer from './components/Footer/Footer';
import ReactGA from 'react-ga';
import GoogleAnalytics from './services/AnalyticService';

library.add(faTrashAlt, faBars, faPhone, faClock, faFacebookSquare, faQuestionCircle, faMapMarkerAlt);


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      html: '',
      image_url: '',
      metaTitle: '',
      metaKeywords: '',
      metaDescription: '',
      isLoading: true,
      error: null,
    }
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    this.fetchSite();
  }

  fetchSite() {
    // Where we're fetching data from api+
    fetch(process.env.REACT_APP_NODE_BACKEND + '/sites/5de919df74749b7758a45242')
      // We get the API response and receive data in JSON format...
      .then(response => {
        this.setState({ status: response.status })
        return response.json();

      })
      // ...then we update the users state
      .then(data => {
        this.setState({
          html: data.html,
          name: data.name,
          image_id: data.image_id,
          image_url: data.image_url,
          metaTitle: data.metaTitle,
          metaKeywords: data.metaKeywords,
          metaDescription: data.metaDescription,
          isLoading: false,
        })
      })
      // Catch any errors we hit and update the app
      .catch(error => this.setState({ error, isLoading: false }));
  }
  render() {
    const { html, image_url, metaTitle, metaDescription, metaKeywords, isLoading } = this.state;
    const main = { image: image_url, metaTitle: metaTitle, metaDescription: metaDescription, metaKeywords: metaKeywords };
    if (isLoading) {
      return (null);
    } else {
      return (
          <BrowserRouter>
            <div className="Page">
              <Header />
              <NavbarHead />
              <Main main={main} />
              <NavbarFoot />
              <Footer html={html} />
            </div>
            <GoogleAnalytics />
          </BrowserRouter>
      );
    }

  }
}

export default App;