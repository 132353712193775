

import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
//import CreditCardInput from 'react-credit-card-input';
import LoadingOverlay from 'react-loading-overlay';
import './Booking.css';
import 'moment/locale/is';
import { Helmet } from 'react-helmet';
moment.locale('is');


require('dotenv').config();

class BookingInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      ssn: '',
      phone: '',
      email: '',
      address: '',
      postCode: '',
      active: false,
      loadingText: 'Bóka bíl vinsamlegast bíðið!',
      response: null,
      extras: [],
      car: '',
      locations: '',
      pickUpDate: '',
      returnDate: '',
      carName: '',
      cardNumber: '',
      cardExpire: '',
      cardCvc: '',
      image: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.location.state) {
      this.props.history.push('/til-leigu-sendibilar');
    } else {
      const { car, location, price, time, extras, cache } = this.props.location.state;
      this.setState({ car, location, price, time, extras, cache }, () => {
        this.props.history.replace({
          pathname: this.props.location.pathname,
          state: null
        });
      });
    }
  }

  handleCardNumberChange = (event) => {
    this.setState({ cardNumber: event.target.value })
  }

  handleCardExpiryChange = (event) => {
    this.setState({ cardExpire: event.target.value })
  }

  handleCardCVCChange = (event) => {
    this.setState({ cardCvc: event.target.value })
  }

  handleFirstNameChange = (event) => {
    this.setState({ firstName: event.target.value })
  }
  handleLastNameChange = (event) => {
    this.setState({ lastName: event.target.value })
  }
  handleSSNChange = (event) => {
    this.setState({ ssn: event.target.value })
  }
  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value })
  }
  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  }
  handleAddressChange = (event) => {
    this.setState({ address: event.target.value })
  }
  handlePostCodeChange = (event) => {
    this.setState({ postCode: event.target.value })
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ active: true }, () => {
      //this.PreAuthorization();
      this.bookInDax();
    })
  }

  bookInDax() {

    const { car, location, price, time, extras, firstName, lastName, ssn, email, phone, cache } = this.state;
    fetch(process.env.REACT_APP_NODE_BACKEND + '/dax/reservation', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        carClass: car.carClass,
        carId: car.carId,
        dateFrom: time.pickup,
        dateTo: time.return,
        location: location.locationCode,
        carPrice: price.carPrice,
        fullAmout: price.carPrice + price.extraPrice + price.insurancePrice,
        extras: extras,
        firstName: firstName,
        lastName: lastName,
        ssn: ssn,
        email: email,
        phone: phone,
        length: cache.length,
        openTime: cache.openTime,
        closeTime: cache.closeTime,
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          //this.cancelPayment();
          throw Error("Eitthvað fór úrskeiðis og ekki tókst að klára bókun vinsamlegast <a href='../hafa-samband'>hafðu samband</a> til að klára bókun");
        }
      })
      .then(data => {
        this.setState({ loadingText: "Klára bókun vinsamlegast bíðið!" }, () => {
          this.createBooking(data);
        });
      })
      .catch(error => this.setState({ error, isLoading: false, active: false, loadingText: 'Bóka bíl vinsamlegast bíðið!' }));
  }

  createBooking(dax) {
    const daxRef = dax.Sendi_VehResRS.VehResRSCore.VehReservation.VehSegmentCore.ConfID._attributes.ID;
    const { car, location, time, firstName, lastName, ssn, email, phone, extras, price } = this.state;
    fetch(process.env.REACT_APP_NODE_BACKEND + '/bookings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: firstName + ' ' + lastName,
        SSN: ssn,
        phone: phone,
        email: email,
        car: car,
        class: car.carClass,
        carName: car.name,
        location: location,
        locationName: location.address + ' ' + location.postCode + ' ' + location.name,
        pickUpDate: time.pickup,
        returnDate: time.return,
        refNumber: daxRef,
        price: price,
        extras: extras,
        dateCreated: new Date()
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          //this.cancelPayment();
          throw Error("Eitthvað fór úrskeiðis og ekki tókst að klára bókun vinsamlegast <a href='./hafa-samband'>hafðu samband</a> til að klára bókun");
        }
      })
      .then(data => {
        this.props.history.push({
          pathname: '/til-leigu-sendibilar/stadfesting',
          state: {
            car: car,
            price: price,
            location: location,
            time: time,
            daxRef: daxRef,
            extras: extras,
          }
        });
      })
      .catch(error => this.setState({ error, isLoading: false, active: false, loadingText: 'Bóka bíl vinsamlegast bíðið!' }));

  }

  render() {
    const { car, location, price, time, /*cardNumber, cardExpire, cardCvc,*/ active, loadingText, error, extras } = this.state;
    return (

      <LoadingOverlay
        active={active}
        spinner
        text={loadingText}
      >
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Container>

          <form onSubmit={this.onSubmit}>
            <h2>Upplýsingar um Leigutaka</h2>
            <Row>
              {car && location && price && time ? (
                <div className="col-md-6 order-md-2">
                  <Row>
                    <Col className="PlaceTimeInfo">
                      <h4>Dags og staðsetningar</h4>
                      <h5>Afhendingar- og skilastaður</h5>
                      <p>{location.address} {location.postCode} {location.name}</p>
                      <p>{location.description}</p>
                      <p>Sendibílum skal skilað á sama stað og þeir eru leigðir.</p>
                      <h5>Sækja bíl</h5>
                      <p>{moment(time.pickup).format('dddd DD.MM.YYYY [Klukkan] HH:mm')}</p>
                      <h5>Skila bíl</h5>
                      <p>{moment(time.return).format('dddd DD.MM.YYYY [Klukkan] HH:mm')}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="CarInfo">
                      <h4>Tryggingar, Aukahlutir og ökutæki</h4>
                      <table style={{ "width": "100%" }}>
                        <tbody>
                          <tr>
                            <td><strong>Tryggingar</strong></td>
                            <td>
                              <p>{extras.insuranceSelected.length > 0 ? (extras.insuranceSelected.map(item => (item.name + ', '))) : ('Enginn')}</p>
                            </td>
                          </tr>
                          <tr>
                            <td valign="top"><strong>Aukahlutir</strong></td>
                            <td>
                              <p>{extras.extrasSelected.length > 0 ? (extras.extrasSelected.map(item => item.name + ', ')) : ('Enginn')}</p>
                            </td>
                          </tr>
                          <tr>
                            <td width="30%"><strong>Ökutæki</strong></td>
                            <td>{car.name}</td>
                          </tr>
                        </tbody>
                      </table>
                      <img src={car.image} alt={car.name} />
                      <div dangerouslySetInnerHTML={{ __html: car.description }} />
                    </Col>
                  </Row>
                </div>
              ) : (null)}
              <div className="col-md-6 order-md-1">
                <Row> <p>Reiti merkta * er skylt að fylla út</p> </Row>
                <label htmlFor="FirstName">Fornafn (sama og er á korti)*</label>
                <Row>
                  <Col><input className="form-control" type="text" required onChange={this.handleFirstNameChange} name="FirstName" id="FirstName" ></input> </Col>
                </Row>
                <label htmlFor="LastName">Eftirnafn (sama og er á korti)*</label>
                <Row>
                  <Col><input className="form-control" type="text" required onChange={this.handleLastNameChange} name="LastName" id="LastName" ></input> </Col>
                </Row>
                <label htmlFor="SSN">Kennitala *</label>
                <Row>
                  <Col><input className="form-control" type="text" pattern='^\d{10}' required onChange={this.handleSSNChange} name="SSN" id="SSN"></input> </Col>
                </Row>
                <label htmlFor="phone">Sími *</label>
                <Row>
                  <Col><input className="form-control" type="tel" pattern='^\d{7}' required onChange={this.handlePhoneChange} name="phone" id="Phone" ></input> </Col>
                </Row>
                <label htmlFor="Email">Netfang *</label>
                <Row>
                  <Col><input className="form-control" type="text" required onChange={this.handleEmailChange} name="email" id="Email"></input> </Col>
                </Row>
                <label htmlFor="Address">Heimilisfang *</label>
                <Row>
                  <Col><input className="form-control" type="text" required onChange={this.handleAddressChange} name="address" id="Address" ></input> </Col>
                </Row>
                <label htmlFor="Zip">Póstnúmer *</label>
                <Row>
                  <Col><input className="form-control" type="text" required onChange={this.handlePostCodeChange} name="zip" id="Zip" ></input> </Col>
                </Row>
                {!price ? (null) : (
                  <Row>
                    <Col className="priceInfo">
                      <h4>Verð (Verð innifelur virðisaukaskatt)</h4>
                      <table style={{ "width": "100%" }}>
                        <tbody>
                          <tr>
                            <td >Leiguverð</td>
                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.carPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td >ISK</td>
                          </tr>
                          <tr>
                            <td >Kílómetragjald innifalið (skattur án vsk)</td>
                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.totalKmPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td >ISK</td>
                          </tr>
                          <tr>
                            <td>Tryggingar</td>
                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.insurancePrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td >ISK</td>
                          </tr>
                          <tr>
                            <td>Aukahlutir</td>
                            <td style={{ "textAlign": "right" }}><CurrencyFormat value={price.extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></td>
                            <td>ISK</td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              <strong>Samtals</strong>
                            </td>
                            <th style={{ "textAlign": "right" }}><strong><CurrencyFormat value={price.carPrice + price.insurancePrice + price.extraPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} /></strong></th>
                            <th>ISK</th>
                          </tr>
                        </tfoot>
                      </table>
                    </Col>
                  </Row>
                )}
                <Row> <Col><input type="checkbox" name="driver" id="Driver" required /> <label htmlFor="Driver">* Ég hef verið með ökuréttindi í +1 ár</label> </Col></Row>
                <Row> <Col><input type="checkbox" name="age" id="Age" required /> <label htmlFor="Age">* Ég er 21+ ára</label> </Col></Row>
                <Row> <Col><input type="checkbox" name="pay" id="Pay" required /> <label htmlFor="Pay">* Ég er með kreditkort (EKKI fyrirframgreitt) á mínu nafni</label> </Col></Row>
                <Row> <Col><input type="checkbox" name="Agreement" id="Agreement" required /> <label htmlFor="Agreement">* Ég samþykki og hef lesið <a href="../skilmalar" target="_blank">skilmála</a></label> </Col></Row>
              </div>

            </Row>
            <Row >
              <Col xs={8} sm={10}>{error ? <p className="Error" dangerouslySetInnerHTML={{ __html: error.message }}></p> : " "}</Col>
              <Col xs={4} sm={2}> <button className="submitButton" style={{ backgroundColor: 'rgb(0, 94, 184)' }} type="submit">STAÐFESTA</button></Col>
            </Row>
          </form>
        </Container>
      </LoadingOverlay>
    );
  }
}



export default BookingInfo;