import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './FrontCategory.css';


class FrontCategory extends Component {

  render() {
    const { image, name, link } = this.props.category;
    return (
        <div className="FrontCategory">
          <div className="FrontCarPic">
            <img src={image} alt={name} />
          </div>
          <div className="infoOfCar">
            <h3 className="name">{name}</h3>
          </div>
          <div className="BTN">
            <Link to={'/flokkur/' + link}><button className="nanarBtn">SKOÐA ÚRVALIÐ</button></Link>
          </div>
        </div>
    );
  };
};



export default FrontCategory;