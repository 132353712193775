import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

// The Footer creates links that can be used to navigate
// between routes.
const NavigationBar = () => (
  <header>
    <nav>
      <ul className="NavFoot">
        <li className="NavFoot"><Link to='/verdskra-sendibila'>Verðskrá</Link></li>
        <li className="NavFoot"><Link to='/sendibilaleiga-til-fyrirtaekja'>Fyrirtækjalausnir</Link></li>
        <li className="NavFoot"><Link to='/opnunartimi'>Opnunartími</Link></li>
        <li className="NavFoot"><Link to='/utleigustadir'>Útleigustaðir</Link></li>
        <li className="NavFoot"><Link to='/sendibilaleiga'>Sendibílar</Link></li>
        <li className="NavFoot"><Link to='/tryggingar'>Tryggingar</Link></li>
        <li className="NavFoot"><Link to='/innifalinn-akstur'>Innifalinn Akstur</Link></li>
        <li className="NavFoot"><Link to='/skilmalar'>Skilmálar</Link></li>
      </ul>
    </nav>
  </header>
)

export default NavigationBar