import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Container, Row, Col, } from 'react-bootstrap';

import './Main.css';
require('dotenv').config();


class QuestAndAns extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            checkBox: false,
            isLoading: true,
            error: null,
            status: 200,
            isValid: false,
        }
    }

    createRequest = (event) => {
        event.preventDefault();
        fetch(process.env.REACT_APP_NODE_BACKEND + '/requests', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message,
                dateCreated: new Date()
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw Error("Rejected with code " + response.status);
                }
            })
            .then(data => {
                this.props.history.push(`/`);
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    onNameChange = event => {
        this.setState({ name: event.target.value });
    }
    onEmailChange = event => {
        this.setState({ email: event.target.value });
    }
    onPhoneChange = event => {
        this.setState({ phone: event.target.value });
    }
    onMessageChange = event => {
        this.setState({ message: event.target.value });
    }
    oncheckBoxChange = event => {
        this.setState({ checkBox: event.target.value });
    }

    render() {
        const metaTitle = "Hafðu samband | Thrifty Sendibílar til leigu";
        const metaDescription = "Hefur þú nánari spurningar eða vantar þig sendibíl í lengri tíma í nokkra daga, viku, mánuð eða nokkra mánuði? Hafðu samband og bókaðu sendibíl hjá okkur.";
        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{metaTitle}</title>
                    <meta name="keywords" content={"Hafa samband"} />
                    <meta name="description" content={metaDescription} />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:url" content={window.location.href} />
                </Helmet>
                <div>
                    <h1>Hafa samband</h1>
                    <form onSubmit={this.createRequest}>
                        <label htmlFor="QNAName">Nafn *</label>
                        <Row>
                            <Col xs={6}><input className="form-control" type="text" required name="name" id="QNAName" onChange={this.onNameChange}></input> </Col>
                        </Row>
                        <label htmlFor="QNAemail">Netfang *</label>
                        <Row>
                            <Col xs={6}><input className="form-control" type="email" pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$" required name="email" id="QNAemail" value={this.state.email} onChange={this.onEmailChange}></input> </Col>
                        </Row>
                        <label htmlFor="QNAphone">Sími</label>
                        <Row>
                            <Col xs={6}><input className="form-control" type="text" name="phone" id="QNAphone" onChange={this.onPhoneChange}></input> </Col>
                        </Row>
                        <label htmlFor="QNAMessage">Skilaboð *</label>
                        <Row>
                            <Col><textarea className="form-control" rows="5" required name="message" id="QNAMessage" onChange={this.onMessageChange}></textarea></Col>
                        </Row>
                        
                        <Row>
                            <Col><input type="checkbox" required name="checkbox" id="QNAPrivacy" onChange={this.onCheckBoxChange}></input>
                            <label htmlFor="QNAPrivacy"> &nbsp; Ég samþykki vinnslu persónuupplýsinga *</label></Col>
                        </Row>
                        <Row>
                            <Col xs={2}><button className="submitButton" type="submit">SENDA</button></Col>
                        </Row>
                    </form>
                </div>
            </Container>
        );
    }

}


export default QuestAndAns;